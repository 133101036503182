const adminUserTypes = ["Administrator"]
const managerUserTypes = ["AccountManager", "Administrator"]

export default [
    {
        text: "Dashboard",
        path: "/dashboard",
        icon: "bi bi-house-fill",
        //allowedUsers: managerUserTypes
    },
    {
        text: "Clients",
        path: "/clients",
        icon: "bi bi-people-fill",
        allowedUsers: managerUserTypes
    },
    {
        text: "Trades",
        path: "/trades",
        icon: "bi bi-currency-exchange",
        allowedUsers: managerUserTypes
    },
    {
        text: "Transfers",
        icon: "bi bi-arrow-left-right",
        allowedUsers: managerUserTypes,
        items: [
            {
                text: "Luno",
                path: "/luno-transfers",
                allowedUsers: managerUserTypes
            },
            {
                text: "Banking",
                path: "/banking-transfers",
                allowedUsers: managerUserTypes
            }
        ]
    },
    {
        text: "System",
        icon: "bi bi-gear-fill",
        allowedUsers: adminUserTypes,
        items: [
            {
                text: "Settings",
                path: "/system-settings",
                allowedUsers: adminUserTypes
            },
            {
                text: "Hangfire",
                path: "/system-hangfire",
                allowedUsers: adminUserTypes
            }
        ]
    }
];