import {loginRequest, msalInstance} from "@/authConfig";

class MsalHelper {

   GetUserName(): any {
        return msalInstance.handleRedirectPromise().then(() => {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                return accounts[0]?.username
            }
        });
    }

    GetUserType(): any {
        return msalInstance.handleRedirectPromise().then(() => {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
                return accounts[0]?.idTokenClaims["extension_UserType"];
            }
        });
    }

    GetClientId(): any {
        return msalInstance.handleRedirectPromise().then(() => {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
                return accounts[0]?.idTokenClaims["extension_ClientId"];
            }
        });
    }
    
    async GetToken(): Promise<string | null> {
        let token: string | null = null;
        try {
            const response = await msalInstance.acquireTokenSilent(loginRequest).catch(error => {
                return msalInstance.acquireTokenRedirect(loginRequest);
            });

            token = response?.accessToken || null;
        } catch (e) {
            console.error(e);
            token = null
        }

        return token;
    }

    async SetTokenHeader(ajaxOptions: any): Promise<void> {
        ajaxOptions.headers = {
            ...ajaxOptions.headers,
            ...await this.AuthorizationHeader()
        };
    }

    async AuthorizationHeader(): Promise<any> {
        const token = await this.GetToken();
        return {
            "Authorization": "Bearer " + token
        };
    }
}

const msalHelper = new MsalHelper()
export default msalHelper;