import {HubConnectionBuilder, HubConnection, LogLevel, HubConnectionState} from '@microsoft/signalr'

import msalHelper from "@/plugins/msalHelper";
import {HubMethodNames} from "@/models/models";

class NotificationsHub {

    private connection: HubConnection;

    constructor() {
        this.connection = this.CreateHub();
    }

    public start(): Promise<void> {
        return this.connection.start().catch(async err => {
            /*
                Sometimes directly after login the connection does not establish because we do not have the valid token yet.
                We need to recreate the hub and try again.
                withAutomaticReconnect() does not work until we establish the connection 1st
             */

            await this.stop();
            this.connection = this.CreateHub();
            setTimeout(() => {
                this.start();
            }, 2000);
        });
    }

    private stop(): Promise<void> {
        if (this.connection) {
            return this.connection.stop();
        }

        return new Promise<void>((resolve) => {
            resolve();
        });
    }

    public async Subscribe(method: HubMethodNames, onMessageReceived: (message: any) => void): Promise<any> {
        const conn = await this.GetOpenConnection();

        conn.on(method, (message) => {
            onMessageReceived(message);
        });
    }

    public async UnSubscribe(method: HubMethodNames): Promise<any> {
        const conn = await this.GetOpenConnection();
        conn.off(method);
    }

    public async SubscribeToGroup(method: HubMethodNames, group: string | Array<string>, onMessageReceived: (message: any) => void): Promise<any> {
        const conn = await this.GetOpenConnection();

        conn.on(method, (message) => {
            onMessageReceived(message);
        });

        await conn.invoke("JoinOrLeaveGroup", method, typeof group === 'string' ? [group] : group, true).catch(function (err) {
            return console.error(err.toString());
        });
    }

    public async UnSubscribeFromGroup(method: HubMethodNames, group: string | Array<string>): Promise<any> {
        const conn = await this.GetOpenConnection();

        await conn.invoke("JoinOrLeaveGroup", method, typeof group === 'string' ? [group] : group, false).catch(function (err) {
            return console.error(err.toString());
        });

        conn.off(method);
    }

    private CreateHub(): HubConnection {
        return new HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_API_URL + '/notifications-hub', {
                accessTokenFactory: async () => {
                    const token = await msalHelper.GetToken();
                    return token as string;
                }
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Error)
            .build();
    }

    private GetOpenConnection(): Promise<HubConnection> {
        return new Promise((resolve, reject) => {
            const msMaxWait = 2000;
            const msWait = 10;

            let ms = 0;

            const idInterval = setInterval(() => {
                if (this.connection.state == HubConnectionState.Connected) {
                    clearInterval(idInterval);
                    resolve(this.connection);
                }

                ms += msWait;

                if (ms >= msMaxWait) {
                    clearInterval(idInterval);
                    reject(this.connection);
                }
            }, msWait);
        });
    }
}

const notificationsHub = new NotificationsHub()
export default notificationsHub;