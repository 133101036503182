import {createRouter, createWebHashHistory, RouteRecordRaw} from "vue-router";
import {registerGuard} from "./Guard";
import navigation from "../app-navigation"

function loadView(view: string) {
    return () => import(`../views/view-${view}.vue`)
}

const routes: Array<RouteRecordRaw> = (navigation as Array<any>).flatMap(e => {
    if(e.items) {
        return (e.items as Array<any>).map(i => {
            return {
                path: i.path,
                name: i.text,
                component: loadView(i.path.toLowerCase().replaceAll("/", "")),
                meta: {
                    requiresAuth: true,
                    allowedUsers: i.allowedUsers
                }
            }
        })
    }

    return {
        path: e.path,
        name: e.text,
        component: loadView(e.path.toLowerCase().replaceAll("/", "")),
        meta: {
            requiresAuth: true,
            allowedUsers: e.allowedUsers
        }
    }
});

routes.push({
    path: "/",
    redirect: "/Dashboard"
});

routes.push({
    path: "/clients/:id?/detail",
    name: "client-detail",
    component: loadView("client-detail"),
    props: true
});

routes.push({
    path: '/failed',
    name: 'Failed',
    component: loadView("login-failed"),
    meta: {
        isFailedLoginRoute: true
    }
});

const router = createRouter({
    routes: routes,
    history: createWebHashHistory()
});

registerGuard(router);

export default router;