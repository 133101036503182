import axios from 'axios';

export const CountryCodeMap = new Map([
    ['US', 'United States'],
    ['ZA', 'South Africa'],
    ['LI', 'Liechtenstein'],
    ['GB', 'United-Kingdom'],
]);

export const CountryCode = {
    US: 'US',
    ZA: 'ZA',
    LI: 'LI',
    GB: 'GB',
} as const;

export interface Holiday {
    date: string;
    localName: string;
    name: string;
    countryCode: string;
    fixed: boolean;
    global: boolean;
    counties: string[] | null;
    launchYear: number | null;
    types: string[];
}

class HolidayService {
    async GetHolidays(year: number, countryCode: string): Promise<Holiday[]> {
        const url = `https://date.nager.at/api/v3/publicholidays/${year}/${countryCode}`;

        return await axios.get(url)
            .then(response => {
                return response.data
            });
    }
}

const holidayService = new HolidayService();
export default holidayService;