export interface Client {
    clientName: string;
    tradeFee: number
    forexFee: number;
}

export interface ClientDetail {
    clientName: string;
    emailAddress: string
}

export interface ClientTrade {
    clientName: string;
    clientId: Number;
    dateCreated: Date;
    dateCompleted: Date;
    tradeAmount: Number;
    exchangeRate: Number;
    arbitrage: Number;
    pinCode: String;
    enableTesting: Boolean;
    status: Number;
    minArbitrage: Number;
    exchangeSource: ExchangeSource
    walletCurrency: WalletCurrency
}

export interface SystemSetting {
    name: string;
    value: string;
}

export interface ClientTradeLog {
    id: string;
    message: string;
    timeStamp: Date;
    logType: number;
    clientTradeId: number;
}

export interface ArbitrageResult {
    exchangeRate: number;
    foreignBtcPrice: number;
    localBtcPrice: number;
    arbitrage: number;
}
export interface TransferBtc {
    from: number;
    to: number;
    amount: number;
}

export interface TransferZar {
    from: number;
    amount: number;
    expressWithdrawal: boolean;
}

export interface BankAccountDetails {
    bankAccountId: number;
    bankName: string;
}

export enum CurrencyPair {
    XBT_ZAR = "XBT_ZAR",
    XBT_EUR = "XBT_EUR",
    XBT_USD = "XBT_USD",
    ZAR_EUR = "ZAR_EUR",
    ZAR_USD = "ZAR_USD"
}


export enum ExchangeSource {
    Luno,
    Kraken,
    Investing,
    Bitstamp
}

export enum WalletCurrency {
    ZAR = 0,
    EUR = 1,
    USD = 2,
    AUD = 3,
    GBP = 4,
    XBT = 5
}

export enum HubMethodNames {

    Balance = "Balance",
    CreateTrade = "CreateTrade",
    DeleteTrade = "DeleteTrade",
    MarketUpdate = 'MarketUpdate',
    Progress = "Progress",
    TradeLog = 'TradeLog',
}

export enum TradeStatus {
    Scheduled = 0,
    InProgress = 1,
    Complete = 2,
    Canceled = 3,
    Error = 4,
    WaitForFunds = 5
}