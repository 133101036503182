import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
// see: https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/samples/msal-browser-samples/vue3-sample-app
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md
// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: '83abd1ed-7780-4805-9e71-a4e49ef3943c',
        authority:  process.env.VUE_APP_AUTHORITY_URL, // Choose sign-up/sign-in user-flow as your default.
        redirectUri: process.env.VUE_APP_AUTH_REDIRECT_URL, // Must be registered as a SPA redirectURI on your app registration
        postLogoutRedirectUri: process.env.VUE_APP_AUTH_REDIRECT_URL, // Must be registered as a SPA redirectURI on your app registration
        knownAuthorities: ['gilfoyleservices.b2clogin.com']
    },
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        //console.debug(message);
                        return;
                    case LogLevel.Warning:
                        //console.warn(message);
                        return;
                    default:
                        return;
                }
            },
            logLevel: LogLevel.Verbose
        }
    }
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["openid", 'https://gilfoyleservices.onmicrosoft.com/trade-api/user'],
};