import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';

import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import "./styles.scss";

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import router from "./router/router";

import ElementPlus from 'element-plus';
import { msalPlugin } from "./plugins/msalPlugin";
import { msalInstance } from "./authConfig";
import { AuthenticationResult, EventType } from "@azure/msal-browser";
import { CustomNavigationClient } from "./router/NavigationClient";

import BootstrapVue3 from 'bootstrap-vue-3';
import FontAwesomeIcon from "@/utils/fontawesome-icons";

import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
createApp.prototype.$http = axios

import DataGrid, { Properties as DataGridOptions } from "devextreme/ui/data_grid";
import Form, { Properties as FormOptions } from "devextreme/ui/form";
import LoadPanel, { Properties as LoadPanelOptions } from "devextreme/ui/load_panel";

import App from './App.vue'
import appInfo from "./app-info";
import notificationsHubPlugin from "@/notifications-hub-plugin";

// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

DataGrid.defaultOptions<DataGridOptions>({
    options: {
        editing: {
            useIcons: true
        },
        loadPanel: {
            indicatorSrc: "https://js.devexpress.com/Content/data/loadingIcons/rolling.svg"
        },
        showColumnLines: false
    }
});

Form.defaultOptions<FormOptions>({
    options: {
        labelMode: 'floating'
    }
});

LoadPanel.defaultOptions<LoadPanelOptions>({
    options: {
        indicatorSrc: "https://js.devexpress.com/Content/data/loadingIcons/rolling.svg",
        shadingColor: "rgba(0,0,0, 0.75)"
    }
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp(App);

app.use(ElementPlus);
app.use(BootstrapVue3);
app.use(router);
app.use(msalPlugin, msalInstance);
app.use(notificationsHubPlugin);
app.use(pinia)

app.component("FontAwesomeIcon", FontAwesomeIcon)

app.config.globalProperties.$appInfo = appInfo;

router.isReady().then(() => {
    // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
    app.mount('#app');
});