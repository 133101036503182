import { defineStore } from 'pinia';
import { ExchangeSource, WalletCurrency } from "@/models/models";

export const useExchangeStore = defineStore({
    id: 'exchange',
    state: () => ({
        exchange: ExchangeSource.Kraken,
        currency: WalletCurrency.EUR,
    }),
    getters: {
        getExchange(state) {
            return state.exchange
        },
        getCurrency(state) {
            return state.currency
        }
    },
    actions: {
        setExchange(value: any) {
            this.exchange = value;
        },
        setCurrency(value: any) {
            this.currency = value;
        },
    },
    persist: false
});